<template>
    <div class="details-auditNode-box">
        <div class="details-title">订单审批跟踪</div>
        <div class="details-cent" v-loading="loading">
            <div class="cent-top flex-start">
                <div class="audit-node">审核节点</div>
                <div class="audit-name">操作人</div>
                <div class="audit-state">审核状态</div>
                <div class="audit-remark">审批意见</div>
                <div class="audit-time">处理时间</div>
            </div>

            <!-- 新写的审核节点 -->
            <div class="cent-top flex-start" v-for="(row, index) in list" :key="index">
                <div class="audit-node">{{row.auditNode}}</div>
                <div class="audit-name" style="line-height:19px">{{row.auditName}}</div>
                <div class="audit-state">{{row.auditState}}</div>
                <div class="audit-remark">{{row.auditRemark}}</div>
                <div class="audit-time">{{row.auditTime}}</div>
            </div>
            
            
            <!-- 整理之前的代码分成各个节点 -->
            <!-- <div class="cent-top flex-start" v-if="auditNodeFn('项目审批员')">
                <div class="audit-node">项目审批员</div>
                <div class="audit-name">{{ordername('项目审批员')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('总部审批员')">
                <div class="audit-node">总部审批员</div>
                <div class="audit-name" style="line-height:19px">{{ordername('总部审批员')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('集采专员')">
                <div class="audit-node">集采专员</div>
                <div class="audit-name" style="line-height:19px">{{ordername('集采专员')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('集采经理')">
                <div class="audit-node">集采经理</div>
                <div class="audit-name">{{ordername('集采经理')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('集采总监')">
                <div class="audit-node">集采总监</div>
                <div class="audit-name">{{ordername('集采总监')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('出纳')">
                <div class="audit-node">出纳</div>
                <div class="audit-name" style="line-height:19px">{{ordername('出纳')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div>
            <div class="cent-top flex-start" v-if="auditNodeFn('财务')">
                <div class="audit-node">财务</div>
                <div class="audit-name" style="line-height:19px">{{ordername('财务')}}</div>
                <div class="audit-state">等待审核</div>
                <div class="audit-remark"></div>
                <div class="audit-time"></div>
            </div> -->
            

        </div>
    </div>
</template>

<script>
import { 
    auditStatus,
    queryNextAuditor,
    queryOrderSettlementPeriod,
    queryExternalOrderAuditStatus
} from "@/api/order/order";

import { auditNode, auditNodeState } from "@/utils/orderAuditNode.js"

export default {
    name: "orderDetailsList",
    props: {
        id: {
            type: [String, Number],
            require: true,
        },
        // listData: {
        //     type: Array,
        //     default: () => []
        // },
        item: Object,
    },
    data() {
        return {
            name: '', // 下级审核节点
            list: [],
            isPayTime: false,// 用于父组件判断是否上传凭证
            loading: true,
        }
    },
    computed: {
        
    },
    mounted() {
        queryOrderSettlementPeriod(this.id).then((res) => {
            if (res.code == '00000') {
                if (res.data == null) {
                    this.getListNodeFn()
                } else {
                    this.OrderAuditStatus()
                }
            } else {
                this.$message.error(res.desc ?? res.data ?? '获取失败！请联系管理员')
            }
        }).catch(() => {
            this.loading = false
        })
    },
    methods: {
        OrderAuditStatus() {
            queryExternalOrderAuditStatus(this.id).then((res) => {
                this.loading = false
                if (res.code == '00000') {
                    this.list = res.data
                } else {
                    this.$message.error(res.desc ?? res.data ?? '获取失败！请联系管理员')
                }
            }).catch(() => {
                this.loading = false
            })
        },
        getAttribute() {
            return this.isPayTime
        },
        // 订单审批节点
        getListNodeFn() {
            auditStatus({orderId: this.id}).then((res) => {
                if(res.code == '00000'){
                    // console.log('---node-list---',res.data);
                    this.isPayTime = !res.data[res.data.length - 1].payTime ? false : true;
                    if(
                        res.data[res.data.length-1].newState != 0 && 
                        res.data[res.data.length-1].newState != 7 &&
                        res.data[res.data.length-1].newState != 9 &&
                        this.item.auditState != 0 &&
                        this.item.auditState != 7 &&
                        this.item.auditState != 9
                    ){
                        this.auditNodeSteps(res.data)
                    } else {
                        this.auditNodeStepsFn(res.data)
                        this.loading = false
                    }
                } else {
                    this.loading = false
                    this.$message.error(res.desc ?? res.data ?? '获取失败！请联系管理员')
                }
            }).catch(() => {
                this.loading = false
            })
        },
        // 下级审批节点接口
        auditNodeSteps(arr) {
            queryNextAuditor({
                "auditState": this.item.auditState,
                "companyId": this.item.organizationId,
                "projectId": this.item.projectId != 0 ? this.item.projectId : '',
                "orderType": this.item.orderType,
                "cardNo": this.item.cardBatchNo
            }).then( res => {
                if(res.code == '00000'){
                    this.auditNodeStepsFn(arr, res.data)
                } else {
                    this.$message.error(res.desc ?? res.data ?? '获取失败！请联系管理员')
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        // 审核节点数据处理
        auditNodeStepsFn(arr, name) {
            let data = arr.map( item => {
                return {
                    auditNode: auditNode(item.oldState),
                    auditName: item.doDataUserName,
                    auditState: auditNodeState(item.oldState, item.newState),
                    auditRemark: item.remark,
                    auditTime: item.createTime,
                }
            })
            if(arr[arr.length-1].newState != 0 && this.item.auditState != 0){

                if(arr[arr.length-1].newState != 7 && arr[arr.length-1].newState != 9){
                    data.push({
                        auditNode: auditNode(arr[arr.length-1].newState),
                        auditName: name,
                        auditState: '等待审核',
                        auditRemark: '',
                        auditTime: '',
                    })
                }
                // 外部企业
                if(arr[arr.length-1].newState == 9 && arr[arr.length-1].oldState == 22) {
                    data.push({
                        auditNode: auditNode(21),
                        auditName: name,
                        auditState: '等待审核',
                        auditRemark: '',
                        auditTime: '',
                    })
                }
                
            } else if(arr[arr.length-1].newState == 0 || this.item.auditState == 0) {
                data.push({
                    auditNode: '-',
                    auditName: '-',
                    auditState: '订单已确认',
                    auditRemark: '',
                    auditTime: '',
                })
            }
            console.log('====node-list====',data);
            this.list = data
        },
        // 审核节点 整理之前的html代码写成的逻辑
        // auditNodeFn(row) {
        //     if(row == '项目审批员' && this.listData.length == 1) {
        //         return true
        //     } else if(row == '总部审批员') {
        //         if(this.item.auditType == 2) {
        //             if(this.listData.length == 2 && this.listData[1].newState != 9 && this.listData[1].newState == 11) {
        //                 return true
        //             }
        //         }
        //     } else if(row == '集采专员') {
        //         if(this.item.auditType == 2) {
        //             if(this.listData.length == 3 && this.listData[2].newState != 9) {
        //                 return true
        //             }
        //         } else {
        //             if(this.listData.length == 2 && this.listData[1].newState != 9) {
        //                 return true
        //             }
        //         }
        //     } else if(row == '集采经理') {
        //         if(this.item.auditType == 2) {
        //             if(this.item.isBatchOrder != 1 && this.item.orderType != 8) {
        //                 if(this.listData.length == 4 && this.listData[3].newState != 9) {
        //                     return true
        //                 }
        //             }
        //         } else {
        //             if(this.item.isBatchOrder != 1 && this.item.orderType != 8) {
        //                 if(this.listData.length == 3 && this.listData[2].newState != 9) {
        //                     return true
        //                 }
        //             }
        //         }
        //     } else if(row == '集采总监') {
        //         if(this.item.auditType == 2) {
        //             if(this.item.isBatchOrder != 1 && this.item.orderType != 8) {
        //                 if(this.listData.length == 5 && this.listData[4].newState != 9) {
        //                     return true
        //                 }
        //             }
        //         } else {
        //             if(this.item.isBatchOrder != 1 && this.item.orderType != 8) {
        //                 if(this.listData.length == 4 && this.listData[3].newState != 9) {
        //                     return true
        //                 }
        //             }
        //         }
        //     } else if(row == '出纳') {
        //         if(this.item.auditType == 2) {
        //             if(this.item.isBatchOrder == 1 || this.item.orderType == 8) {
        //                 if(this.listData.length == 4 && this.listData[3].newState != 9) {
        //                     return true
        //                 } else if(this.listData.length == 6 && this.listData[5].newState != 9 && this.listData[5].newState != 7) {
        //                     return true
        //                 }
        //             } else {
        //                 if(this.listData.length == 6 && this.listData[5].newState != 9) {
        //                     return true
        //                 } else if(this.listData.length == 8 && this.listData[7].newState != 9 && this.listData[7].newState != 7) {
        //                     return true
        //                 }
        //             }
        //         } else {
        //             if(this.item.isBatchOrder == 1 || this.item.orderType == 8) {
        //                 if(this.listData.length == 3 && this.listData[2].newState != 9) {
        //                     return true
        //                 } else if(this.listData.length == 5 && this.listData[4].newState != 9 && this.listData[4].newState != 7) {
        //                     return true
        //                 }
        //             } else {
        //                 if(this.listData.length == 5 && this.listData[4].newState != 9) {
        //                     return true
        //                 } else if(this.listData.length == 7 && this.listData[6].newState != 9 && this.listData[6].newState != 7) {
        //                     return true
        //                 }
        //             }
        //         }
        //     } else if(row == '财务') {
        //         if(this.item.auditType == 2) {
        //             if(this.item.isBatchOrder == 1 || this.item.orderType == 8) {
        //                 if(this.listData.length == 5 && this.listData[4].newState != 9 && this.listData[4].newState != 7) {
        //                     return true
        //                 } else if(this.listData.length == 7 && this.listData[6].newState != 9 && this.listData[6].newState != 7) {
        //                     return true
        //                 }
        //             } else {
        //                 if(this.listData.length == 7 && this.listData[6].newState != 9 && this.listData[6].newState != 7) {
        //                     return true
        //                 } else if(this.listData.length == 9 && this.listData[8].newState != 9 && this.listData[8].newState != 7) {
        //                     return true
        //                 }
        //             }
        //         } else {
        //             if(this.item.isBatchOrder == 1 || this.item.orderType == 8) {
        //                 if(this.listData.length == 4 && this.listData[3].newState != 9 && this.listData[3].newState != 7) {
        //                     return true
        //                 } else if(this.listData.length == 6 && this.listData[5].newState != 9 && this.listData[5].newState != 7) {
        //                     return true
        //                 }
        //             } else {
        //                 if(this.listData.length == 6 && this.listData[5].newState != 9 && this.listData[5].newState != 7) {
        //                     return true
        //                 } else if(this.listData.length == 8 && this.listData[7].newState != 9 && this.listData[7].newState != 7) {
        //                     return true
        //                 }
        //             }
        //         }
        //     }
        //     return false
        // },

    }
}

</script>

<style lang="scss" scoped>
.details-auditNode-box {
    width: 100%;
    border: 1px solid #d7d7d7;
    margin-bottom: 20px;
    .details-title {
        height: 40px;
        line-height: 40px;
        padding-left: 13px;
        background: #f2f2f2;
        font-weight: bold;
        font-size: 15px;
    }
    .details-cent {
        
        .cent-top {
            width: 98%;
            // height: 46px;
            // line-height: 46px;
            margin: 0 10px;
            border-bottom: 1px solid #e1e1e1;
            padding: 15px 4px;
            .top-staut {
                width: 60%;
            }
            .top-infor {
                width: 50%;
            }
            .top-time {
                width: 30%;
            }
            .audit-node {
                width: 20%;
            }
            .audit-name {
                width: 19%;
                text-align: center;
                word-break: break-word;
                white-space: pre-line;
                // word-break: keep-all;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
            .audit-state {
                width: 30%;
                text-align: center;
            }
            .audit-remark {
                width: 40%;
                // text-align: center;
            }
            .audit-time {
                width: 23%;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
</style>