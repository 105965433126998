<template>
    <div class="logistics-info-box">
        <div class="info-title-box">
            <div class="title">物流信息</div>
            <div class="logistics-scroll">
                <div
                    class="logistics"
                    v-for="i in list.length"
                    :key="i"
                    :class="{
                        active: i - 1 == cur,
                    }"
                    @click="tabs(i - 1)"
                >
                    包裹{{ i }}
                </div>
            </div>
            <!-- <div class="logistics">包裹2</div> -->
        </div>
        <div class="content" v-loading="loading">
            <div style="width: 500px;">
                <el-table
                    :data="goodsList"
                    border
                    :header-cell-style="{
                        background: '#f2f2f2',
                        color: '#333',
                        textAlign: 'center',
                    }"
                    style="width: 100%;text-align: center;"
                >
                    <!-- <el-table-column prop="skuId" align="center" label="商品编号" width="200"></el-table-column> -->
                    <el-table-column prop="goodsName" label="商品">
                        <template slot-scope="{ row }">
                            <div class="table-goods">
                                <img :src="row.goodsPic" alt="" />
                                <span style="margin-left: 15px;">{{
                                    row.goodsName
                                }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsNum"
                        label="数量"
                        align="center"
                        width="80"
                    >
                    </el-table-column>
                </el-table>
            </div>

            <div class="receiving-express" v-if="expressList.length > 0">
                <span class="title">{{ expressList[0].expCompany }}</span>
                <span>：</span>
                <span id="copyID">{{ expressList[0].expNo }}</span>
                <el-tooltip
                    content="复制成功"
                    placement="top"
                    manual
                    v-model="copyTip"
                >
                    <span class="copy" v-if="expressList[0].expNo" @click="copy"
                        >复制</span
                    >
                </el-tooltip>
            </div>

            <!-- <div class="order-remark" v-if="remark">
                <span class="title">备注</span>
                <span>：</span>
                <span class="content">{{ remark }}</span>
            </div> -->

            <div
                v-if="
                    expressList.length > 0 &&
                        expressList[0].expressProcess != null
                "
            >
                <div
                    class="logistics-steps-box"
                    v-for="(item, i) in expressList[0].expressProcess.data"
                    :key="i"
                    :class="{
                        currtent: i == 0,
                    }"
                >
                    <div class="point"></div>
                    <div class="item-box">
                        <div class="steps">
                            <span v-if="i == 0">{{
                                stateFn(expressList[0].expressProcess.state)
                            }}</span>
                            <span v-else>{{ item.Location }}</span>
                            <span>{{ item.AcceptTime }}</span>
                        </div>
                        <div class="remarks">
                            {{ item.AcceptStation }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'logisticsInfo',
    data() {
        return {
            cur: 0,
            parcelList: [],
            goodsList: [],
            expressList: [],
            remark: '',
            loading: true,
            copyTip: false,
        }
    },
    props: {
        list: Array,
    },
    mounted() {
        console.log('logisticsList')
        this.parcelList = this.list
        this.goodsList = this.list[0].shippedGoodsVO
        this.expressList = this.list[0].mapList
        // this.remark = this.list[0].remark
        this.loading = false
    },
    methods: {
        // 切换
        tabs(i) {
            // console.log(i);
            if (this.cur == i) return
            this.cur = i
            this.goodsList = this.list[i].shippedGoodsVO
            this.expressList = this.list[i].mapList
            // this.remark = this.list[i].remark
        },
        // 复制
        copy() {
            if (this.copyTip) return
            let el = document.getElementById('copyID')
            let range = document.createRange() // create new range object
            range.selectNodeContents(el) // set range to encompass desired element text
            let selection = window.getSelection() // get Selection object from currently user selected text
            selection.removeAllRanges() // unselect any user selected text (if any)
            selection.addRange(range) // add range to Selection object to select it
            let copy = document.execCommand('copy')
            if (copy) {
                this.copyTip = true
                setTimeout(() => {
                    this.copyTip = false
                }, 800)
            }
        },
        // 状态
        stateFn(s) {
            switch (s) {
                case 0:
                    return '暂无轨迹信息'
                case 1:
                    return '已揽收'
                case 2:
                    return '在途中'
                case 3:
                    return '已签收'
                case 4:
                    return '问题件'
                default:
                    return ''
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.logistics-info-box {
    width: 100%;
    border: 1px solid #d7d7d7;
    margin-bottom: 20px;

    .info-title-box {
        padding: 0 13px;
        background: #f2f2f2;
        font-size: 15px;
        display: flex;
        align-items: baseline;

        .title {
            height: 50px;
            line-height: 45px;
            font-weight: bold;
            flex-shrink: 0;
        }
        .logistics-scroll {
            display: flex;
            overflow-x: scroll;
            height: 40px;
            align-items: center;
            padding: 0 30px;

            &::-webkit-scrollbar {
                width: 10px;
                height: 8px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }
        }
        .logistics {
            font-size: 13px;
            margin: 0 30px 0 0;
            cursor: pointer;
            flex-shrink: 0;

            &:hover {
                color: #cd9f49;
            }
        }
        .active {
            color: #cd9f49;
        }
    }

    .content {
        padding: 20px 30px;

        .logistics-steps-box {
            margin-top: 12px;
            display: flex;
            align-items: center;
            color: #7f7f7f;

            .point {
                width: 12px;
                height: 12px;
                background-color: #e4e4e4;
                border-radius: 50%;
                margin: 0 2px;
                flex-shrink: 0;
            }
            .item-box {
                margin-left: 10px;
                font-size: 13px;
            }
            .remarks {
                margin-top: 8px;
            }
            .steps span {
                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 14px;
                    color: #7f7f7f;
                }
                &:nth-child(2) {
                    font-size: 13px;
                    margin-left: 10px;
                }
            }
        }
        .currtent {
            .point {
                width: 16px;
                height: 16px;
                background-color: #e4e4e4;
                border-radius: 50%;
                margin: 0;
            }
            .steps span {
                &:nth-child(1) {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000;
                }
            }
        }
        .receiving-express {
            margin: 20px 0;
            font-size: 13px;
            color: #7f7f7f;

            .copy {
                color: #cd9f49;
                margin: 0 10px;
                cursor: pointer;
            }
        }
        .order-remark {
            display: flex;
            font-size: 13px;
            color: #7f7f7f;

            .title {
                width: 52px;
                text-align: justify;
                text-align-last: justify;
            }
            
            span {
                flex-shrink: 0;
            }

            .content {
                width: 435px;
                overflow: hidden;
                white-space: normal;
                word-break: break-all;
                padding: 0;
                flex-shrink: 1;
            }
        }

        .table-goods {
            display: flex;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
            }
        }
    }
}
</style>
