<template>
    <div>
        <Header :seet="number" />
        <el-row
            type="flex"
            class="account—settings"
        >
            <el-col
                :span="3"
                style="width: 15.3%"
            ></el-col>
            <el-col :span="17">
                <div class="sett_box">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>订单中心</el-breadcrumb-item>
                        <el-breadcrumb-item @click.native="goto()">我的订单</el-breadcrumb-item>
                        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
                        <!-- {{ transferInfo }} -->
                    </el-breadcrumb>
                    <div style="display: flex">
                        <div class="box_left">
                            <LeftHurdle />
                        </div>
                        <div
                            class="box_right"
                            v-for="item in goodsList"
                            :key="item.orderNo"
                        >
                            <div v-if="goodsOrderNo == item.orderNo">

                                <div class="order-form flex-between">
                                    <div
                                        class="flex-start"
                                        style="flex-wrap: wrap;"
                                    >
                                        <div
                                            v-if="item.orderNo != item.forderNo"
                                            class="from-number"
                                        >
                                            <span>主订单号：</span>
                                            <span>{{ item.forderNo }}</span>
                                        </div>
                                        <div class="from-number">
                                            <span>订单号：</span>
                                            <span>{{ item.orderNo }}</span>
                                        </div>
                                        <div
                                            class="from-number"
                                            v-if="item.externalOrderNo!=null"
                                        >
                                            <span>第三方订单号：</span>
                                            <span>{{ item.externalOrderNo }}</span>
                                        </div>
                                        <div class="from-staut">
                                            <span>订单状态：</span>
                                            <span class="staut">{{
                                                stateName(
                                                    item.auditState,
                                                    item.state
                                                )
                                            }}</span>
                                        </div>

                                        <div
                                            class="from-staut lookApproveFileBtn"
                                            @click="lookApproveFileBtn(item)"
                                        >
                                            <span>查看审批文件</span>
                                        </div>
                                    </div>
                                    <!-- <div style="width: 350px"> -->
                                    <!-- 采购员 -->
                                    <div
                                        style="display: flex;justify-content: flex-end;"
                                        v-if="authorityJudgment(3)"
                                    >
                                        <template>
                                            <div
                                                v-if="operationBtn('审批',item.auditState, item.state) && item.canAudit == true"
                                                class="cancelord"
                                                @click="auditOrdersBtn(item)"
                                            >
                                                审核
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="operationBtn('对公转账信息', item.auditState, item.state) && item.payTime == null"
                                                @click="transferInfo = true"
                                            >
                                                对公转账信息
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="operationBtn('上传汇款信息', item.auditState, item.state) && item.payTime == null"
                                                @click="isSubmit()"
                                            >
                                                上传汇款凭证
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="
                                                        operationBtn('取消订单', item.auditState, item.state) == '待发货' && 
                                                        item.cannotCancel != true && 
                                                        item.orderGoodsList[0].sourceType != 3"
                                                @click="unorder(item)"
                                            >
                                                {{aftersaleBtnName(item.state,item.payTime, item.auditState)}}
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-else-if="
                                                        operationBtn('取消订单', item.auditState, item.state) == '待付款' && 
                                                        item.orderGoodsList[0].sourceType != 3"
                                                @click="unorder(item)"
                                            >
                                                {{aftersaleBtnName(item.state,item.payTime, item.auditState)}}
                                            </div>
                                            <div class="cancelord"
                                                v-else-if="operationBtn('取消订单', item.auditState, item.state) == 'jump'"
                                                @click="unorder(item, 1)"
                                            >
                                                申请售后
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-else-if="operationBtn('取消订单', item.auditState, item.state) && item.cannotCancel != true"
                                                @click="unorder(item)"
                                            >
                                                {{aftersaleBtnName(item.state,item.payTime, item.auditState)}}
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-else-if="operationBtn('取消订单', item.auditState, item.state, item.payTime) && item.auditState >= 20"
                                                @click="unorder(item)"
                                            >
                                                {{aftersaleBtnName(item.state,item.payTime, item.auditState)}}
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="operationBtn('确认收货', item.auditState, item.state) && item.orderAssociationType != 4"
                                                @click="affirmorder(item.id)"
                                            >
                                                确认收货
                                            </div>
                                            <div class="cancelord"
                                                v-if="operationBtn('重新购买', item.auditState, item.state)"
                                                @click="buyAgainBtn(item)"
                                            >
                                                重新购买
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="operationBtn('重新加入购物车', item.auditState, item.state)"
                                                @click="addToCartAgain(item)"
                                            >
                                                重新加入购物车
                                            </div>
                                            <div
                                                class="cancelord"
                                                v-if="item.sign && userId == item.memberId"
                                                @click="applyActivate(item.sign)"
                                            >
                                                申请激活
                                            </div>

                                        </template>

                                    </div>

                                    <!-- 审批员 -->
                                    <div
                                        style="display: flex"
                                        v-else-if="authorityJudgment([2, 4])"
                                    >
                                        <template v-if="stateName(item.auditState, item.state) == '待审核'">
                                            <div
                                                class="cancelord"
                                                v-if="item.canAudit==true"
                                                @click="auditOrdersBtn(item)"
                                            >
                                                审核
                                            </div>
                                        </template>
                                    </div>
                                    <!-- </div> -->
                                </div>

                                <!-- 订单审批跟踪 -->
                                <AuditNodeList
                                    v-if="authorityJudgment(3)"
                                    ref="AuditNodeListBox"
                                    :id="ordernumber"
                                    :item="item"
                                />

                                <div
                                    class="details—top"
                                    v-if="authorityJudgment(3)"
                                >
                                    <div class="details—name">订单状态</div>
                                    <div class="details-cent" v-loading="orderListLoading">
                                        <div class="cent-top flex-start">
                                            <div class="top-staut">处理信息</div>
                                            <div class="top-infor">处理时间</div>
                                            <div class="top-time">操作人</div>
                                        </div>
                                        <div class="cent-top flex-start">
                                            <div class="top-staut">您提交了订单，请等待系统确认</div>
                                            <div class="top-infor">
                                                <div>{{item.createTime}}</div>
                                            </div>
                                            <div class="top-time">
                                                {{userName}}
                                            </div>
                                        </div>
                                        <div
                                            class="cent-top flex-start"
                                            v-for="(itm,ide) in orderList"
                                            :key="ide"
                                        >
                                            <div class="top-staut">{{oldState(itm.newState) }}</div>
                                            <div class="top-infor">
                                                <div>{{ itm.createTime }}</div>
                                            </div>
                                            <!-- {{stateStr[itm.stateType][itm.newState]}} -->
                                            <div class="top-time">
                                                {{ newName(itm.newState) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 物流信息 -->
                                <LogisticsInfo
                                    v-if="(stateName(item.auditState, item.state) == '待收货' ||
                                        stateName(item.auditState, item.state) == '已完成'||
                                        stateName(item.auditState, item.state) == '待付款') && 
                                        logisticsList.length != 0"
                                    ref="LogisticsInfoBox"
                                    :list="logisticsList"
                                />
                                
                                <div class="payment-box">
                                    <div class="payment-top">
                                        订单信息
                                        <span class="payment-title">买家信息:{{ userName }}</span>
                                        <span class="payment-title">{{ userPhone }}</span>
                                        <span class="payment-title">{{ projectName }}</span>
                                        <span class="payment-title">{{ companyName }}</span>
                                    </div>
                                    <div class="payment-inforbox flex-start">
                                        <div class="payment-infor">
                                            <div class="infor-top">收件人信息</div>
                                            <div>收货人:{{ item.customerName }}</div>
                                            <div>地址：{{ item.address }}</div>
                                            <div>手机号：{{ item.phoneNo }}</div>
                                        </div>
                                        <div class="payment-infor">
                                            <div class="infor-top">支付信息</div>
                                            <div>
                                                订单总金额：¥{{ item.totalAmount }}
                                            </div>
                                            <div>运费：¥{{ item.freight }}</div>
                                            <div>合计：¥{{ item.payAmount }}</div>
                                        </div>
                                        <div
                                            class="payment-infor"
                                            v-for="(iem,inx) in invoiceList"
                                            :key="inx"
                                        >
                                            <div class="infor-top">发票信息</div>
                                            <div>开票方式：{{iem.invoicingTypeName}}</div>
                                            <div v-if="iem.invoiceType==2">发票类型：纸质专票</div>
                                            <div v-else>发票类型：{{iem.typeName}}</div>
                                            <div>
                                                发票抬头：{{iem.companyName}}
                                            </div>
                                            <div>发票内容：{{iem.content}}</div>
                                            <div
                                                style="color:#999;"
                                                v-show="item.auditState==5&&(item.state==1 || item.state==2 || item.state==3 ||item.state==5)"
                                                @click="detailsinvoice(iem)"
                                            >查看发票细节</div>
                                        </div>
                                        <div class="payment-infor">
                                            <div class="infor-top">备注</div>
                                            <div v-show="item.customerNote == null">
                                                <div>暂无</div>
                                            </div>
                                            <div v-show="item.customerNote != null">
                                                {{ item.customerNote }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="item.isBatchOrder == 1 && item.orderGoodsBatchNo != ''">
                                    <div class="goods-none">批量下单详情</div>
                                    <div
                                        class="commodity-box"
                                        style="margin-bottom:10px"
                                    >
                                        <el-table
                                            :data="orderNoList"
                                            border
                                            :header-cell-style="{background:'#cccccc',color:'#333',textAlign: 'center'}"
                                            :cell-style="{ textAlign: 'center' }"
                                            style="width: 100%;text-align: center;"
                                        >
                                            <el-table-column
                                                prop="skuId"
                                                label="商品SKU编号"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="goodsName"
                                                label="商品名称"
                                                width="200"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                v-if="isExternalOrderNoList == 2"
                                                prop="externalCorporateName"
                                                label="外部企业名称"
                                                width="200"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="skuValues"
                                                label="规格"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="num"
                                                label="数量"
                                                width="80"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="receiveName"
                                                label="收件人"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="receivePhone"
                                                label="联系电话"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                prop="address"
                                                label="收货地址"
                                                width="200"
                                            >
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>

                                <div class="goods-none">
                                    <div class="goods-none-title">
                                        <span>商品清单</span>
                                        <div>
                                            <el-button
                                                v-if="item.cardBatchNo"
                                                :loading="purchaseCardDownloadBtn"
                                                type="text"
                                                class="goods-none-button"
                                                @click="purchaseCardDetailed(item)"
                                            >购卡明细下载</el-button>
                                            <el-button
                                                v-if="isShowCardExportBtn"
                                                :loading="cardExportBtn"
                                                type="text"
                                                class="goods-none-button"
                                                @click="cardExport(item.cardBatchNo)"
                                            >发卡明细预览</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="commodity-box">
                                    <el-table
                                        :data="tableData"
                                        border
                                        :header-cell-style="{
                                            background: '#',
                                            color: '#333',
                                            textAlign: 'center',
                                        }"
                                        :cell-style="{ textAlign: 'center' }"
                                        style="width: 100%; text-align: center"
                                    >
                                        <el-table-column
                                            prop="goodsId"
                                            label="商品编号"
                                            width="140"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="name"
                                            label="商品"
                                            width="429"
                                        >
                                            <template slot-scope="{ row }">
                                                <div v-if="Array.isArray(row.infor)">
                                                    <div
                                                        v-for="(
                                                            adr, index
                                                        ) in row.infor"
                                                        :key="index"
                                                        class="rowBox flex-center"
                                                    >
                                                        <img
                                                            :src="adr.img"
                                                            alt=""
                                                            style="width:100px"
                                                        />
                                                        <p style="padding-left: 8px;">
                                                            <span
                                                                class="title_name"
                                                                v-show="adr.sourceType == 0"
                                                            >自营</span>
                                                            <span
                                                                class="title_name"
                                                                v-show="adr.sourceType == 1"
                                                            >京东</span>
                                                            <span
                                                                class="title_name"
                                                                v-show="adr.sourceType == 2"
                                                            >震坤行</span>
                                                            <span
                                                                class="title_name"
                                                                v-show="adr.sourceType == 3"
                                                            >怡亚通</span>
                                                            {{ adr.name }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <span v-else>{{ row.infor }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="goodsPrice"
                                            label="单价"
                                            width="150"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="number"
                                            label="数量"
                                            width="150"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="subtotal"
                                            label="小计"
                                            width="150"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="handle"
                                            label="操作"
                                            width="150"
                                        >
                                            <template slot-scope="scope">
                                                <div
                                                    style="color:#CD9F49;"
                                                    @click="handleClick(scope.$index,scope.row)"
                                                >查看</div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col
                :span="3"
                style="width: 15.5%; position: relative; z-index: -1"
            ></el-col>
        </el-row>
        <Footer />
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>是否审核通过？</span>
            <el-input
                v-model="remark"
                style="margin-top:10px;"
            ></el-input>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    :loading="ddshBtnLoading"
                    type="danger"
                    size="small"
                    @click="ddsh(0)"
                >审核驳回</el-button>
                <el-button
                    :loading="ddshBtnLoading"
                    type="primary"
                    size="small"
                    @click="ddsh(3)"
                >审核通过</el-button>
                <el-button
                    size="small"
                    @click="dialogVisible = false"
                >取消</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="对公转账信息"
            :visible.sync="transferInfo"
            width="30%"
            center
        >
            <div class="transferInfo">
                <div class="item">
                    <p class="title">户 名</p>
                    <p class="content">厦门禹佳科技有限公司</p>
                </div>
                <div class="item">
                    <p class="title">账 号</p>
                    <p class="content">35101564901052522123</p>
                </div>
                <div class="item">
                    <p class="title">开户行</p>
                    <p class="content">中国建设银行股份有限公司厦门城市建设支行</p>
                </div>
                <!-- <div class="item">
                    <p class="title">联行号</p>
                    <p class="content">300050060066（非必填)</p>
                </div> -->
                <!-- <div class="item">
                    <p class="title">汇付识别码</p>
                    <p class="content">YZ12374568525</p>
                </div> -->
                <div class="item">
                    <p class="title">注意事项</p>
                    <p class="content">
                        汇款时将订单编号填写至汇款单“用途”,“附言”等栏。
                    </p>
                </div>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    style="
                        background-color: #cd9f49;
                        color: #ffffff;
                        width: 80%;
                        margin-top: 50px;
                    "
                    @click="transferInfo = false"
                >确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            class="applicationForm"
            title="认款申请单"
            :visible.sync="applicationFormVisible"
            width="60%"
            center
        >
            <h3>1.填写汇款信息</h3>
            <el-form
                :model="applicationForm"
                :inline="true"
                :rules="applicationFormRules"
                ref="applicationForm"
            >
                <el-form-item
                    label="汇款公司"
                    prop="remittanceCompany"
                >
                    <el-input
                        v-model="applicationForm.remittanceCompany"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    label="交易流水号"
                    label-width="100px"
                    prop="serialNumber"
                >
                    <el-input
                        v-model="applicationForm.serialNumber"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->
                <el-form-item
                    label="银行账户"
                    prop="bankAccount"
                >
                    <el-input
                        v-model="applicationForm.bankAccount"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    label="凭证号"
                    label-width="100px"
                    prop="voucherNo"
                >
                    <el-input
                        v-model="applicationForm.voucherNo"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->
                <el-form-item
                    label="开户银行"
                    prop="bankName"
                >
                    <el-input
                        v-model="applicationForm.bankName"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="汇款时间" label-width="100px" prop="remittanceTime">
                    <el-input
                        v-model="applicationForm.remittanceTime"
                        autocomplete="off"
                    ></el-input>
                </el-form-item> -->
                <el-form-item
                    label-width="100px"
                    label="汇款金额"
                    prop="remittanceAmount"
                    class="number"
                >
                    <el-input-number
                        v-model="applicationForm.remittanceAmount"
                        :min="0"
                        :max="999999999"
                        label="描述文字"
                    ></el-input-number>
                </el-form-item>
            </el-form>

            <h3>2.上传汇款凭证</h3>
            <p>
                注：支持的图片格式为jpg，jpeg，png，gif，bmp，pdf。务必上传清晰图片，单个图片需≤10M。
            </p>
            <div
                class="flex"
                style="margin: 15px 0"
            >
                <span>银行电子回执单：</span>
                <span
                    v-if="applicationForm.fileLogList.length == 0"
                    style="margin: 0 15px"
                >未上传</span>
                <span
                    v-else
                    style="margin: 0 15px"
                >已上传</span>
            </div>

            <!-- <el-upload
                class="upload-demo"
                action="/api/orders/order/subscription-application-form/uploadImaage"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-remove="beforeRemove"
                :headers="headers"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                :file-list="applicationForm.remittanceVoucherArr"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
            >
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload> -->
            <el-upload
                v-if="!disabled"
                class="approveUpload-file"
                ref="remittanceVoucherUp"
                action=""
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :multiple="true"
                :auto-upload="true"
                :show-file-list="false"
                :before-upload="beforeVoucherFile"
                :http-request="requestVoucherFile"
            >
                <el-button
                    size="small"
                    type="primary"
                >点击上传</el-button>
            </el-upload>
            <el-button
                v-else
                size="small"
                type="primary"
                :disabled="disabled"
            >点击上传</el-button>
            <div class="dialogVisibleVoucherBox">
                <div
                    class="VoucherFileList"
                    v-if="remittanceVoucher.length > 0"
                >
                    <div
                        class="fileList flex-between"
                        v-for="(item,index) in remittanceVoucher"
                        :key="index"
                    >
                        <div style="width: 68%;">凭证{{index+1}}：{{item.fileName}}</div>
                        <div class="fileBtn">
                            <span @click="approvePreview(item)">在线预览</span>
                            <el-popconfirm
                                v-if="!disabled"
                                title="确定删除吗？"
                                @confirm="VoucherDel(item,index)"
                            >
                                <span slot="reference">删除</span>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div
                    class="VoucherFileList"
                    v-else
                >
                    <el-empty
                        description="无凭证图片"
                        :image-size="40"
                    ></el-empty>
                </div>
            </div>

            <!-- 图片预览 -->
            <el-image
                ref="previewImg"
                style="width: 100px; height: 100px"
                fit="fill"
                v-show="false"
                :src="picturePreview"
                :preview-src-list="[picturePreview]"
            ></el-image>

            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="applicationFormVisible = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="saveEntity('applicationForm')"
                    :disabled="disabled"
                >提 交</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisibleing"
            width="20%"
        >
            <div style="margin-bottom:5px;">
                <div style="margin-bottom:5px;">取消订单需要拨打京东客服电话</div>
                <div>上班时间：【9：00-21:00】</div>
            </div>
            <div>
                <div style="margin-bottom:5px;">电话1</div>
                <div>05922505012</div>
            </div>
            <div style="margin:15px 0;">
                <div style="margin-bottom:5px;">电话2</div>
                <div>18650006780</div>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisibleing = false">关 闭</el-button>
            </span>
        </el-dialog>

        <!-- 汇款凭证 预览 jpg/png/pdf -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="closeShowViewer"
            :url-list="showViewerList"
            style="z-index: 3000"
        />
        <!-- 查看审批文件 -->
        <approveFile
            :fileList="approveFileList"
            :dialog.sync="dialogVisibleApprove"
            :delBtn="approveFileDelBtn"
            @del="approveDel"
            @before="beforeApproveFile"
            @request="requestApproveFile"
        ></approveFile>
    </div>
</template>

<script>
import Header from "@/components/Layout/header.vue"
import Footer from "@/components/Layout/footer.vue"
import LeftHurdle from "@/components/Layout/leftHurdle.vue"
import {
    auditOrders, saveEntity, editEntity, orderafter, deleteOrder, buyAgain,
    addToCartAgain, affirmorder, isSubmit, unhideorder, getBatchList,
    getPurchaserInformation, lookApproveFile, queryOrderDetails,
    uploadApproveFile, delApproveFile, updateByCard, exporCardList, isShowCardExport
} from "@/api/order/order"
import { applydetails, cancelPaidOrder } from "@/api/order/aftersale"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import approveFile from '@/components/public/approveFile.vue'
import AuditNodeList from './components/orderAuditNodeList.vue'
import LogisticsInfo from './components/logisticsInfo.vue'
// import loginVue from '../login.vue';
import { authorityJudgment } from "@/utils/util"
import { auditStateFun, auditBtnFn } from "@/utils/orderAuditNode.js"
export default {
    components: { Header, Footer, LeftHurdle, ElImageViewer, approveFile, AuditNodeList, LogisticsInfo },
    data() {
        return {
            number: "4",
            goodsList: [],
            goodsOrderNo: '',
            tableData: [],
            orderNo: "",
            orderId: "",
            approveFileDelBtn: true,
            dialogVisibleApprove: false,// 审批文件弹窗
            dialogVisibleApproveID: '',// 审批文件订单ID
            approveFileList: [],// 审批文件列表
            remittanceVoucher: [],// 认款申请单文件列表
            showViewer: false,
            showViewerList: [],// 图片预览
            // showPDF: false,
            // srcPDF: '',// pdf预览
            // titlePDF: '',
            dialogVisible: false,
            ddshBtnLoading: false,
            transferInfo: false, //对公转账信息
            applicationFormVisible: false, //认款申请单
            applicationForm: {
                bankAccount: "", //银行账户
                bankName: "", //开户银行
                orderId: 0, //	订单id
                remittanceAmount: 0, //汇款金额
                remittanceCompany: "", //汇款公司
                remittanceTime: "", //汇款时间
                // remittanceVoucher: "", //汇款凭证图片（多个用“,”隔开）(暂时无用)
                // remittanceVoucherArr: [], //汇款凭证图片数组(暂时无用)
                fileLogList: [],  //汇款凭证图片数组
                // serialNumber: "", //流水号(暂时无用)
                // voucherNo: "", //汇款凭证号(暂时无用)
            },
            picturePreview: "", //图片预览
            applicationFormRules: {
                bankAccount: [
                    {
                        required: true,
                        message: "请输入银行账户",
                        trigger: "blur",
                    },
                ],
                bankName: [
                    {
                        required: true,
                        message: "请输入开户银行",
                        trigger: "blur",
                    },
                ],
                remittanceCompany: [
                    {
                        required: true,
                        message: "请输入汇款公司",
                        trigger: "blur",
                    },
                ],
                remittanceAmount: [
                    {
                        required: true,
                        message: "请输入汇款金额",
                        trigger: "blur",
                    },
                ],
                // remittanceTime: [
                //     {
                //         required: true,
                //         message: "请输入汇款时间",
                //         trigger: "blur",
                //     },
                // ],
                // serialNumber: [
                //     {
                //         required: true,
                //         message: "请输入交易流水号",
                //         trigger: "blur",
                //     },
                // ],
                // voucherNo: [
                //     {
                //         required: true,
                //         message: "请输入汇款凭证号",
                //         trigger: "blur",
                //     },
                // ],
            },
            // orderNo:'',
            // dialogVisible:false,
            invoiceId: 0,//发票id
            invoiceList: [],//发票信息
            ordernumber: '',
            orderList: [],
            orderListLoading: false,
            stateType2: 0,  //stateType2第一次出现的位置
            stateType1: 0,  //stateType1第一次出现的位置
            stateStr: {
                2: {
                    1: '您提交了订单，请等待审批员审核',
                    2: '您提交了订单，请等待审批员审核',
                    3: '您提交了订单，请等待审批员审核',
                    4: '您提交了订单，请等待审批员审核',
                    5: '您提交了订单，请等待审批员审核',
                    6: '您提交了订单，请等待审批员审核',
                    7: '您的订单已审核',
                    9: '您的订单已被驳回',
                },
                1: {
                    1: '您的订单已上传汇款凭证',
                    2: '您的订单正在打包中请耐心等待',
                    3: '您的订单等待签收',
                    5: '您的订单已完成，感谢您的支持！',
                    7: '您的订单已取消！',
                }
            },  //状态对应的文字
            parcelList: [],//包裹数量
            act: 0,
            logisticsList: [],//物流信息
            remark: '',
            disabled: false,
            disabledEdit: false,
            editVoucherId: '',
            dialogVisibleing: false,
            headers: { Authorization: localStorage.getItem('token') },
            userName: '',
            userPhone: '',
            projectName: '',
            companyName: '',
            orderNoList: [],
            isExternalOrderNoList: 1,
            orderGoodsBatchNo: '',
            mergeId: '',
            identityState: '',
            projectId: '',
            companyId: '',
            namelist: '',
            authorityJudgment,
            purchaseCardDownloadBtn: false,
            isShowCardExportBtn: false,
            cardExportBtn: false,
        }
    },
    computed: {
        userId() {
            return this.$store.state.userId
        },
        stateName() {
            return (auditState, state) => {
                return auditStateFun(auditState, state)
            }
        },
        operationBtn() {
            return (btn, auditState, state, payTime) => {
                return auditBtnFn(btn, auditState, state, payTime)
            }
        },
        oldState() {
            return (newState) => {
                if (newState === 1) {
                    return "请及时付款"
                } else if (newState === 2 || newState === 10) {
                    return "您的订单正在打包中，请耐心等待"
                } else if (newState === 3) {
                    return "您的订单已邮寄，请注意查收"
                } else if (newState === 12) {
                    return "您的订单已完成，感谢您的支持！"
                } else if (newState === 7) {
                    return "您提交的订单已取消，请重新购买"
                }
            }
        },
        newName() {
            return (newState) => {
                if (newState === 1 || newState === 2 || newState === 10 || newState === 3 || newState === 7) {
                    return "系统"
                } else if (newState === 12) {
                    return this.userName
                }
            }
        },
        GoodsInfor: {
            get() { return this.$store.state.common.GoodsInfor },
            set(val) { this.$store.commit('common/upgoodsInfor', val) }
        },
        MultipleSelection: {
            get() { return this.$store.state.common.MultipleSelection },
            set(val) { this.$store.commit('common/upmultipleSelection', val) }
        },
    },
    created() {
        this.executeFn()
    },
    methods: {
        isShowCardExportFn(cardBatchNo) {
            isShowCardExport({cardBatchNo}).then(res => {
                if(res.code == '00000' && res.data){
                    this.isShowCardExportBtn = true
                }
            })
        },
        executeFn() {
            let itemList = JSON.parse(this.$route.query.item)
            // console.log(itemList)
            this.goodsOrderNo = itemList.orderNo
            queryOrderDetails(itemList.id).then(res => {
                if (res.code == '00000') {
                    this.isShowCardExportFn(res.data.cardBatchNo)
                    this.goodsList = [res.data]
                    this.orderGoodsBatchNo = res.data.orderGoodsBatchNo
                    this.projectId = res.data.projectId
                    this.companyId = res.data.organizationId
                    this.orderId = res.data.id
                    this.mergeId = res.data.mergeId
                    // res.data.forEach(item => {
                    //     this.orderId = item.id
                    //     this.mergeId = item.mergeId
                    // })
                    res.data.orderGoodsList.forEach(item => {
                        let obj = {}
                        let objs = {}
                        let infor = []
                        objs.img = item.goodsPic
                        objs.name = item.goodsName
                        objs.sourceType = item.sourceType
                        infor.push(objs)
                        obj.infor = infor
                        obj.goodsId = item.goodsId
                        obj.goodsPrice = item.goodsPrice
                        obj.number = item.number
                        obj.subtotal = (item.goodsPrice * item.number).toFixed(2)
                        this.tableData.push(obj)
                    })
                    if (this.orderGoodsBatchNo != '') {
                        let rem = {
                            "batchNo": this.orderGoodsBatchNo ?? '',
                        }
                        getBatchList(rem).then((data) => {
                            if (data && data.code == '00000') {
                                this.isExternalOrderNoList = data.data.type
                                this.orderNoList = data.data.batchList
                            }
                        })
                    }
                    getPurchaserInformation([
                        res.data.organizationId,
                        res.data.projectId,
                        res.data.memberId,
                    ]).then((data) => {
                        // console.log(data,'getPurchaserInformation');
                        if (data && data.code == '00000') {
                            this.userName = data.data.name
                            this.userPhone = data.data.phone
                            this.projectName = data.data.projectName ? data.data.projectName : ''
                            this.companyName = data.data.companyName ? data.data.companyName : ''
                        }
                    })
                }
            })

            this.setFormat()
            this.invoiceId = itemList.invoiceId
            this.ordernumber = itemList.id
            applydetails({
                "invoiceId": this.invoiceId,
                "type": 1
            }).then((data) => {
                if (data && data.code == '00000') {
                    this.invoiceList.push(data.data)
                }
            })
            this.orderListLoading = true
            orderafter({
                "orderId": this.ordernumber,
            }).then((data) => {
                if (data && data.code == '00000') {
                    this.orderList = data.data.auditStatusList
                    this.stateType2 = data.data.auditStatusList.findIndex(item => item.stateType === 2)
                    this.stateType1 = data.data.auditStatusList.findIndex(item => item.stateType === 1)
                    // 物流信息
                    this.logisticsList = data.data.sendOutGoodsVOS
                    // console.log('logisticsList',this.logisticsList);
                }
                this.orderListLoading = false
            })
        },
        // 购卡明细下载
        purchaseCardDetailed(item) {
            if(this.purchaseCardDownloadBtn) return;
            this.purchaseCardDownloadBtn = true
            lookApproveFile({
                id: item.id,
                type: 3
            }).then(res => {
                if (res.code == '00000') {
                    let arr = res.data
                    if (arr.length > 0) {
                        for (let i = 0; i < arr.length; i++) {
                            const link = document.createElement("a")
                            link.href = decodeURIComponent(arr[i].fileUrl)
                            link.target = "_blank"
                            link.download = arr[i].fileName
                            link.click()
                        }
                    } else {
                        this.$message({ message: "历史遗留订单，无法下载！", type: 'warning' })
                    }
                }
            }).finally(() => {
                this.purchaseCardDownloadBtn = false
            })
        },
        // 导出发卡明细预览
        cardExport(cardBatchNo){
            if(this.cardExportBtn) return;
            this.cardExportBtn = true
            exporCardList({cardBatchNo}).catch(err => {
                console.log('%cexporCardListError:'+err,`background: #FFF0F0;color: red;`);
            }).finally(() => {
                this.cardExportBtn = false
            })
        },
        setFormat() {
            Date.prototype.format = function (format) {
                var o = {
                    "M+": this.getMonth() + 1, // month
                    "d+": this.getDate(), // day
                    "h+": this.getHours(), // hour
                    "m+": this.getMinutes(), // minute
                    "s+": this.getSeconds(), // second
                    "q+": Math.floor((this.getMonth() + 3) / 3), // quarter
                    S: this.getMilliseconds(),
                    // millisecond
                }
                if (/(y+)/.test(format)) {
                    format = format.replace(
                        RegExp.$1,
                        (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                    )
                }
                for (var k in o) {
                    if (new RegExp("(" + k + ")").test(format)) {
                        format = format.replace(
                            RegExp.$1,
                            RegExp.$1.length == 1
                                ? o[k]
                                : ("00" + o[k]).substr(("" + o[k]).length)
                        )
                    }
                }
                return format
            }
        },
        // handleSuccess(file, fileList) {
        //     if (file.status == "success" || file.code == "00000") {
        //         this.applicationForm.remittanceVoucherArr.push({
        //             uid: fileList.response.uid,
        //             name: fileList.name,
        //             url: fileList.response.data,
        //         });
        //     } else {
        //         this.$message.error(file.desc);
        //         this.handleRemove();
        //     }
        // },
        // handleRemove(file, fileList) {
        //     this.applicationForm.remittanceVoucherArr =
        //         fileList.length != 0 ? fileList : [];
        // },
        // handlePreview(file) {
        //     if (file.status == "success" || file.response.code == "00000") {
        //         // console.log("aaaaa", this.$refs.previewImg);
        //         this.picturePreview = file.url || file.response.data;
        //         this.$refs.previewImg.clickHandler();
        //     }
        // },
        // handleExceed(files, fileList) {
        //     this.$message.warning(
        //         `当前限制选择 3 个文件，本次选择了 ${
        //             files.length
        //         } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        //     );
        // },
        // beforeRemove(file, fileList) {
        //     return this.$confirm(`确定移除 ${file.name}？`, fileList);
        // },
        // 上传文件前
        beforeVoucherFile(file) {
            console.log(file)
            const fileSuffix2 = file.name.substring(file.name.lastIndexOf(".") + 1)

            const whiteList2 = ["jpg", "jpeg", "png", "gif", "bmp", "pdf", "JPG", "JPEG", "PBG", "GIF", "BMP", "PDF"]
            const VoucherSize = 10485760

            if (this.remittanceVoucher.length >= 10) {
                this.$message({ message: "最多上传10个文件", type: 'error' })
                return false
            }
            // let aName = this.remittanceVoucher.find(item => item.fileName == file.name)
            // if(aName){
            //     this.$message({message:"重复上传文件名！", type: 'warning'});
            //     return false;
            // }
            if (whiteList2.indexOf(fileSuffix2) === -1) {
                this.$message({ message: "上传格式错误！", type: 'warning' })
                return false
            } else if (VoucherSize <= file.size) {
                this.$message({ message: "上传文件的大小需小于等于10MB", type: 'warning' })
                return false
            }
        },
        // 自定义上传
        requestVoucherFile(params) {
            // console.log(params,'up3');

            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 2)
            // formData.append("correlationId", this.mergeId)

            uploadApproveFile(formData).then(res => {

                if (res.code == '00000') {
                    this.$message({ message: "上传成功", type: 'success' })
                    this.remittanceVoucher.push(res.data)
                    this.applicationForm.fileLogList.push({
                        fileName: res.data.fileName,
                        fileUrl: res.data.fileUrl,
                        type: res.data.type,
                    })
                } else {
                    this.$message({ message: "上传失败", type: 'error' })
                }
            })
        },
        VoucherDel(item, index) {
            console.log(item)
            // const loading = this.$loading({
            //     lock: true,
            //     text: '正在删除中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // });
            this.remittanceVoucher.splice(index, 1)
            this.applicationForm.fileLogList.splice(index, 1)
            this.$message({ message: "删除成功", type: 'success' })
            // loading.close();

        },
        //认款申请单
        saveEntity(formName) {
            console.log(this.applicationForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.applicationForm.fileLogList.length != 0) {
                        // this.applicationForm.remittanceVoucher = "";
                        // let remittanceVoucher = this.applicationForm.remittanceVoucherArr
                        //     .map((item) => item.url)
                        //     .join(",");
                        // this.applicationForm.fileLogList = remittanceVoucher;
                        this.applicationForm.orderId = this.mergeId
                        this.applicationForm.remittanceTime = new Date().format(
                            "yyyy-MM-dd hh:mm:ss"
                        )
                        if (!this.disabledEdit) {
                            saveEntity(this.applicationForm).then((res) => {
                                if (res.code == "00000") {
                                    this.$message({
                                        type: "success",
                                        message: res.desc,
                                    })
                                    this.applicationFormVisible = false
                                    this.disabled = true
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.desc,
                                    })
                                }
                            })
                        } else {
                            console.log('editEntity')
                            let data = {
                                ...this.applicationForm,
                                id: this.editVoucherId
                            }
                            editEntity(data).then((res) => {
                                if (res.code == "00000") {
                                    this.$message({
                                        type: "success",
                                        message: res.desc,
                                    })
                                    this.applicationFormVisible = false
                                    this.disabled = true
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.desc,
                                    })
                                }
                            })
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: "请上传汇款凭证！",
                        })
                        return
                    }
                } else {
                    // console.log("error submit!!");
                    return false
                }
            })
        },
        //订单审核
        auditOrdersBtn(item) {
            this.orderId = item.id
            this.orderNo = item.orderNo
            this.dialogVisible = true
        },
        //订单审核
        ddsh(status) {
            // this.dialogVisible = false;
            if (status == 0) {
                if (this.remark == '') {
                    this.$message({
                        type: "error",
                        message: '请填写驳回原因',
                    })
                } else {
                    this.ddshBtnLoading = true
                    auditOrders({
                        orderNo: this.orderNo,
                        status,
                        orderId: this.orderId,
                        remark: this.remark
                    }).then((res) => {
                        if (res.code == "00000") {
                            this.$message({
                                type: "success",
                                message: '驳回成功！',
                            })
                            this.$router.go(0)
                        } else {
                            this.$message({
                                type: "error",
                                message: res.desc ?? '审核失败！',
                            })
                        }
                        this.dialogVisible = false
                        this.ddshBtnLoading = false
                    }).catch(() => { this.ddshBtnLoading = false })
                }
            } else {
                this.ddshBtnLoading = true
                auditOrders({
                    orderNo: this.orderNo,
                    status,
                    orderId: this.orderId,
                    remark: this.remark
                }).then((res) => {
                    if (res.code == "00000") {
                        this.$message({
                            type: "success",
                            message: '审核成功！',
                        })
                        this.$router.go(0)
                    } else {
                        this.$message({
                            type: "error",
                            message: res.desc ?? '审核失败！',
                        })
                    }
                    this.dialogVisible = false
                    this.ddshBtnLoading = false
                }).catch(() => { this.ddshBtnLoading = false })
            }
        },
        //查看发票明细
        detailsinvoice(item) {
            // localStorage.setItem("prizeResult",JSON.stringify(item))
            let data = {
                id: item.invoiceId
            }
            this.$router.replace({ name: 'InvoiceDetails', query: { item: JSON.stringify(data) } })
        },
        goto() {
            this.$router.replace({ name: 'MyOrder' })
        },
        // 取消订单按钮名称
        aftersaleBtnName(state, payTime, type) {
            if (payTime) {
                return state == 1 ? '取消订单' : '申请售后'
            } else {
                return state == 2 || state == 3 || state == 5 || (state == 1 && type == 7) ? '申请售后' : '取消订单'
            }
        },
        // 取消订单/申请售后
        unorder(item, jump) {
            console.log(item.payTime, item.state, item.auditState, item.orderGoodsList[0].sourceType, '-----unorder-----')
            if(jump) {
                this.$router.push({ name: 'ChangingRefund', query: { orderNo: item.orderNo} })
                return
            }
            if (item.payTime) {
                if (item.orderGoodsList[0].sourceType == 1) {
                    this.dialogVisibleing = true
                } else if (item.state == 1) {
                    this.cancelOrderConfirm(item.id)
                } else {
                    this.afterSaleConfirm(item.id)
                }
            } else {
                if (item.state == 2 || item.state == 3 || item.state == 5 || (item.state == 1 && item.auditState == 7)) {
                    this.afterSaleConfirm(item.id)
                } else {
                    this.cancelOrderConfirm(item.id)
                }
            }

        },
        // 申请售后确认
        afterSaleConfirm(id) {
            this.$confirm("是否申请售后", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    cancelPaidOrder(id).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                duration: 8000,
                                message: "请前往售后记录查看！",
                            })

                        } else if (data.code == "E0001") {
                            this.$message({
                                type: "error",
                                duration: 8000,
                                message: data.desc,
                            })
                        }
                    })
                })
                .catch(() => { })
        },
        // 取消订单确认
        cancelOrderConfirm(id) {
            this.$confirm("是否取消订单", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let obj = {
                        orderId: id,
                    }
                    unhideorder(obj).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "取消成功!",
                            })

                        } else if (data.code == "E0001") {
                            this.$message({
                                type: "error",
                                duration: 8000,
                                message: data.desc,
                            })
                        }
                    })
                })
                .catch(() => { })
        },
        //删除订单
        deleteOrder(id) {
            this.$confirm("确认删除订单", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    deleteOrder(id).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            })
                            this.$router.replace({ name: 'MyOrder' })

                        }
                    })
                })
                .catch(() => { })
        },
        //重新购买
        buyAgainBtn(item) {
            this.$confirm("是否重新购买?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在加载中···',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.4)'
                    });
                    
                    buyAgain({ orderId: item.id }).then((data) => {
                        loading.close();
                        if (data && data.code == "00000") {
                            if(data.data.length > 0){
                                let arr = data.data.map(item => ({
                                    actType: item.actType,
                                    goodsId: item.goodsId,
                                    skuId: item.skuId,
                                    nums: item.num,
                                    kind: item.kind
                                }))
                                // console.log(arr);
                                this.GoodsInfor = JSON.stringify(arr)
                                this.MultipleSelection = ''
                                this.$router.push({ name: "GoodsClose" });
                            } else {
                                this.$message.warning('获取数据为空！')
                            }
                        } else {
                            this.$message.error(data.desc ?? '操作失败！')
                        }
                    });
                })
                .catch(() => {});
        },
        //重新加入购物车
        addToCartAgain(item) {
            this.$confirm("是否重新加入购物车", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    console.log(item)
                    let data = {
                        orderId: item.id,
                    }
                    addToCartAgain(data).then((data) => {
                        console.log(data)
                        if (data && data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "添加成功！",
                            })
                        } else {
                            this.$message({
                                type: "error",
                                message: data.desc,
                            })
                        }
                    })
                })
                .catch(() => { })
        },
        //确认收货
        affirmorder(id) {
            this.$confirm("是否确认收到货", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let data = {
                        orderId: parseInt(id),
                    }
                    affirmorder(data).then((data) => {
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: "确认成功!",
                            })
                            this.$router.replace({ name: 'MyOrder' })
                        }
                    })
                })
                .catch(() => { })
        },
        //查看
        handleClick(id, item) {
            console.log(id, item)
            this.$router.replace({ name: 'goodsDetail', query: { id: item.goodsId } })
        },
        //查看是否上传凭证
        isSubmit() {
            this.applicationFormVisible = true
            // console.log(this.$refs['AuditNodeListBox'][0].isPayTime)
            if (!this.$refs['AuditNodeListBox'][0].isPayTime) {
                this.disabled = false
            } else {
                this.disabled = true
            }
            let data = {
                "orderId": this.mergeId
            }
            isSubmit(data).then((data) => {
                if (data.code === '00000') {
                    if (data.data != null) {
                        this.disabledEdit = true
                        this.editVoucherId = data.data.id
                        this.applicationForm.bankAccount = data.data.bankAccount
                        this.applicationForm.bankName = data.data.bankName
                        this.applicationForm.remittanceAmount = data.data.amount
                        this.applicationForm.remittanceCompany = data.data.remittanceCompany
                        this.applicationForm.remittanceTime = data.data.remittanceTime
                        this.applicationForm.serialNumber = data.data.serialNumber
                        this.applicationForm.voucherNo = data.data.voucherNo
                        // if(data.data.remittanceVoucher!=''){
                        //     if (data.data.remittanceVoucher) {
                        //         let arr = data.data.remittanceVoucher.split(",");
                        //         // console.log(arr, "arr");
                        //         // console.log(this.applicationForm.remittanceVoucherArr, "this.form");
                        //         if(arr.length==0){
                        //             this.applicationForm.remittanceVoucherArr =[]
                        //         }else{
                        //             this.applicationForm.remittanceVoucherArr =[]
                        //             arr.forEach((item)=>{
                        //                 if(item!=''){
                        //                     let temp = {
                        //                         name:item,
                        //                         url: item,
                        //                     };
                        //                     this.applicationForm.remittanceVoucherArr.push(temp);
                        //                 }
                        //             })
                        //         }
                        //     }
                        // }
                    } else {
                        this.disabledEdit = false
                        this.applicationForm.bankAccount = ''
                        this.applicationForm.bankName = ''
                        this.applicationForm.remittanceAmount = ''
                        this.applicationForm.remittanceCompany = ''
                        this.applicationForm.remittanceTime = ''
                        // this.applicationForm.serialNumber=''
                        // this.applicationForm.voucherNo=''
                        // this.applicationForm.remittanceVoucher=""
                        // this.applicationForm.remittanceVoucherArr=[]
                    }
                }
            })
            // 查看凭证
            lookApproveFile({
                id: this.mergeId,
                type: 2
            }).then(res => {
                console.log(res)
                if (res.code == '00000') {
                    this.remittanceVoucher = res.data.filter(item => item.type == 2)
                    this.applicationForm.fileLogList = res.data.filter(item => item.type == 2 && delete item.id)
                }
            })

        },
        approvePreview(item){
            // console.log(item);
            this.showViewer = true
            this.showViewerList = [item.fileUrl]
        },
        // 查看审批文件按钮
        lookApproveFileBtn(item) {
            this.dialogVisibleApprove = true
            this.dialogVisibleApproveID = item.id
            if (item.auditState > 1) {
                this.approveFileDelBtn = false
            } else {
                this.approveFileDelBtn = true
            }

            lookApproveFile({
                id: item.id,
                type: 1
            }).then(res => {
                console.log(res)
                if (res.code == '00000') {
                    this.approveFileList = res.data.filter(item => item.type == 1)
                    // this.approveFileList = [{fileName:'asdf.jpg',fileUrl:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'}]
                }
            })

        },
        closeShowViewer() {
            this.showViewer = false
        },
        closeShowPDF() {
            this.showPDF = false
        },
        // 审批文件删除
        approveDel(item, index) {
            console.log(item)
            const loading = this.$loading({
                lock: true,
                text: '正在删除中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            delApproveFile(item.id).then(res => {
                loading.close()
                if (res.code == '00000') {
                    this.$message({ message: "删除成功", type: 'success' })
                    this.approveFileList.splice(index, 1)
                } else {
                    this.$message({ message: "删除失败", type: 'error' })
                }
            })
        },
        // 审批文件上传
        // 上传文件前
        beforeApproveFile(file) {
            console.log(file)
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1)

            const whiteList = ["jpg", "png", "pdf", "JPG", "PNG", "PDF"]
            const size = 10485760

            if (this.approveFileList.length >= 10) {
                this.$message({ message: "最多上传10个文件", type: 'error' })
                return false
            }
            // let aName = this.approveFileList.find(item => item.fileName == file.name)
            // if(aName){
            //     this.$message({message:"重复上传文件名！", type: 'warning'});
            //     return false;
            // }
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message({ message: "上传文件只能是 jpg,png,pdf格式", type: 'warning' })
                return false
            } else if (size <= file.size) {
                this.$message({ message: "上传文件的大小需小于等于10MB", type: 'warning' })
                return false
            }
        },
        // 自定义上传
        requestApproveFile(params) {
            // console.log(this.upApproveFileList,'up');
            console.log(params, 'up2')

            let formData = new FormData()
            formData.append("file", params.file)
            formData.append("type", 1)
            formData.append("correlationId", this.dialogVisibleApproveID)

            uploadApproveFile(formData).then(res => {

                if (res.code == '00000') {
                    this.$message({ message: "上传成功", type: 'success' })
                    this.approveFileList.push(res.data)
                } else {
                    this.$message({ message: "上传失败", type: 'error' })
                }
            })
        },
        // 申请激活
        applyActivate(id) {
            if(!id) return;
            this.$confirm("是否确认申请激活", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在处理中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    updateByCard({
                        id,
                        status: 1,
                    }).then((data) => {
                        // console.log(data);
                        if (data.code == "00000") {
                            this.$message({
                                type: "success",
                                message: data.desc || "操作成功！",
                            });
                            this.$router.go(0)
                        } else {
                            this.$message({
                                type: "error",
                                message: data.desc,
                            });
                        }
                    }).finally(() => {
                        loading.close()
                    })
                })
                .catch(() => {});
        }

    }

};
</script>

<style lang="scss">
.dialogVisibleVoucherBox {
    width: 100%;
    min-height: 150px;
    height: auto;
    position: relative;

    .VoucherFileList {
        width: 100%;
        height: auto;
        margin: 10px 0 0;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background: #ededed;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 30%;
                color: #cd9f49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
}
.dialogVisibleApproveBox {
    width: 100%;
    height: 320px;
    position: relative;

    .approveFileList {
        width: 100%;
        height: 225px;
        margin-bottom: 15px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background: #ededed;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 32%;
                color: #cd9f49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
    .hint-box {
        width: 100%;
    }
}
.approveUpload-file {
    display: inline-block;
    margin-right: 30px;
}
.transferInfo {
    .item {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 20%;
            flex-shrink: 0;
            margin-right: 5%;
        }
    }
}
.applicationForm {
    .el-input {
        width: 300px;
    }
    .number {
        .el-input {
            width: 100%;
        }
    }
    h3 {
        margin-bottom: 20px;
    }
}
.sett_box {
    margin: 30px 0;
    .el-breadcrumb {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .box_left {
        margin-right: 20px;
    }
    .box_right {
        width: 87%;
        border-radius: 10px;
        .order-form {
            padding: 18px;
            border: 1px solid #cd9f49;
            background: #fbfbfb;
            margin-bottom: 10px;
            .from-number {
                margin: 6px 10px 6px 0;
            }
            .from-staut {
                margin-right: 15px;
                .staut {
                    color: #cd9f49;
                }
            }
            .cancelord {
                background: #cd9f49;
                padding: 8px;
                color: #fff;
                border-radius: 4px;
                margin-left: 5px;
                font-size: 14px;
                text-align: center;
                cursor: pointer;
                line-height: 1;
                white-space: nowrap;
            }
            .lookApproveFileBtn {
                margin: 0 12px 0 0;
                cursor: pointer;
                text-align: center;

                &:hover {
                    color: #cd9f49;
                }
            }
        }
        .details—top {
            width: 100%;
            border: 1px solid #d7d7d7;
            margin-bottom: 20px;
            .details—name {
                height: 40px;
                line-height: 40px;
                padding-left: 13px;
                background: #f2f2f2;
                font-weight: bold;
                font-size: 15px;
            }
            .cent-top {
                width: 98%;
                // height: 46px;
                // line-height: 46px;
                margin: 0 10px;
                border-bottom: 1px solid #e1e1e1;
                padding: 15px 4px;
                .top-staut {
                    width: 60%;
                }
                .top-infor {
                    width: 50%;
                }
                .top-time {
                    width: 30%;
                }
                .top-stauts {
                    width: 20%;
                }
                .top-name {
                    width: 19%;
                    text-align: center;
                    word-break: break-word;
                    white-space: pre-line;
                    // word-break: keep-all;
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }
                .top-infors {
                    width: 30%;
                    text-align: center;
                }
                .top-yijian {
                    width: 40%;
                    // text-align: center;
                }
                .top-times {
                    width: 23%;
                }
            }
        }
        .details-way {
            width: 96.5%;
            padding: 20px;
            border: 1px solid #d7d7d7;
            margin: 20px 0;
            .way-name {
                margin-top: 15px;
            }
        }
        .way-logistics {
            width: 96.5%;
            padding: 15px 20px;
            border: 1px solid #d7d7d7;
            margin-top: 10px;
            .logistics_name {
                margin-bottom: 15px;
            }
            .logistics_box {
                background: #f0f8fa;
                .logistics-top {
                    width: 100%;
                    border: 1px solid #333;
                    line-height: 40px;
                    height: 40px;
                    font-weight: bold;
                    text-align: center;
                    overflow-x: auto;
                    div {
                        width: 33.3%;
                        border-right: 1px solid #333;
                    }
                }
                .logistics-top::-webkit-scrollbar {
                    width: 4px;
                    height: 8px;
                }
                .logistics-top::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
                    background: rgba(0, 0, 0, 0.1);
                }
                .logistics-top::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
                    border-radius: 0;
                    background: rgba(0, 0, 0, 0);
                }
                .logistics-cent {
                    font-size: 15px;
                    .logistics-infor {
                        margin: 10px 0;
                        // border-right: 1px solid #d7d7d7;
                        padding: 20px 15px;
                        .infor-test {
                            margin: 15px 0;
                        }
                        .expressProcess:first-child {
                            color: #cd9f49 !important;
                        }
                    }

                    .logistics-goods {
                        width: 30%;
                        margin: 0 50px;
                        img {
                            width: 60px;
                        }
                    }
                }
            }
        }
        .payment-box {
            width: 100%;
            border: 1px solid #d7d7d7;
            margin: 20px 0;
            .payment-top {
                width: 98.7%;
                height: 40px;
                line-height: 40px;
                padding-left: 15px;
                background: #f2f2f2;
                font-weight: bold;
                .payment-title {
                    color: #555555;
                    font-size: 15px;
                    margin-left: 10px;
                }
            }
            .payment-inforbox {
                width: 100%;
                margin: 10px 8px;
                .payment-infor {
                    width: 21.5%;
                    height: 300px;
                    margin: 0 8px;
                    padding: 0 10px;
                    border: 1px solid #c9c9c9;
                    .infor-top {
                        text-align: center;
                        padding: 10px 0;
                    }
                }
            }
        }
        .goods-none {
            margin-bottom: 10px;
            .goods-none-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .goods-none-button {
                    margin-right: 10px;
                    color: #e4aa40;
                    font-size: 15px;
                }
            }
        }
        .commodity-box {
            width: 100%;
            border: 1px solid #d7d7d7;
            .title_name {
                color: #fff;
                background: #cd9f49;
                padding: 2px 4px;
                font-size: 12px;
                border-radius: 4px;
            }
        }
    }
}
</style>