var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logistics-info-box"},[_c('div',{staticClass:"info-title-box"},[_c('div',{staticClass:"title"},[_vm._v("物流信息")]),_c('div',{staticClass:"logistics-scroll"},_vm._l((_vm.list.length),function(i){return _c('div',{key:i,staticClass:"logistics",class:{
                    active: i - 1 == _vm.cur,
                },on:{"click":function($event){return _vm.tabs(i - 1)}}},[_vm._v(" 包裹"+_vm._s(i)+" ")])}),0)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content"},[_c('div',{staticStyle:{"width":"500px"}},[_c('el-table',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"data":_vm.goodsList,"border":"","header-cell-style":{
                    background: '#f2f2f2',
                    color: '#333',
                    textAlign: 'center',
                }}},[_c('el-table-column',{attrs:{"prop":"goodsName","label":"商品"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('div',{staticClass:"table-goods"},[_c('img',{attrs:{"src":row.goodsPic,"alt":""}}),_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(_vm._s(row.goodsName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"goodsNum","label":"数量","align":"center","width":"80"}})],1)],1),(_vm.expressList.length > 0)?_c('div',{staticClass:"receiving-express"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.expressList[0].expCompany))]),_c('span',[_vm._v("：")]),_c('span',{attrs:{"id":"copyID"}},[_vm._v(_vm._s(_vm.expressList[0].expNo))]),_c('el-tooltip',{attrs:{"content":"复制成功","placement":"top","manual":""},model:{value:(_vm.copyTip),callback:function ($$v) {_vm.copyTip=$$v},expression:"copyTip"}},[(_vm.expressList[0].expNo)?_c('span',{staticClass:"copy",on:{"click":_vm.copy}},[_vm._v("复制")]):_vm._e()])],1):_vm._e(),(
                _vm.expressList.length > 0 &&
                    _vm.expressList[0].expressProcess != null
            )?_c('div',_vm._l((_vm.expressList[0].expressProcess.data),function(item,i){return _c('div',{key:i,staticClass:"logistics-steps-box",class:{
                    currtent: i == 0,
                }},[_c('div',{staticClass:"point"}),_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"steps"},[(i == 0)?_c('span',[_vm._v(_vm._s(_vm.stateFn(_vm.expressList[0].expressProcess.state)))]):_c('span',[_vm._v(_vm._s(item.Location))]),_c('span',[_vm._v(_vm._s(item.AcceptTime))])]),_c('div',{staticClass:"remarks"},[_vm._v(" "+_vm._s(item.AcceptStation)+" ")])])])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }